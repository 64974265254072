$default-font-size: 14px;

$main-width: 800px;
$head-height: 55px;
$head-height-big: 70px;

$heading-font-size: 24px;
$heading-font-size-narrow: 28px;

$margin-width: 16px;
$body-margin-width-top: $margin-width;
$body-margin-width-left: $margin-width;
$body-margin-width-right: $margin-width;
$body-margin-width-bottom: $margin-width;

$margin-width-top: $margin-width;
$margin-width-left: $margin-width;
$margin-width-right: $margin-width;
$margin-width-bottom: $margin-width;

$navigation-links-padding-top: 12px;

//$navigation-links-height: 39px;
$navigation-links-height-bottom: 56px;
$navigation-links-height-top: 39px;

$navigation-position-height: 34px;

//$foot-height: 90px;

// $grey: #F0F0EB;  // is not being displayed in some computer screens
$grey: rgb(221, 221, 221);
$text-grey: #595959;
//$text-grey: #adada7;
$light-green: #e8f5ed;
$dark-green: #007055;
$dark-dark-green: darken($dark-green, 10%);
$red: #e52626;
$dark-red: #a00000;
$light-orange: #fff3e1;
$orange: #ffb84c;
$peach: #fef3e1;

// fonts
$galano: "GalanoGrotesque-SemiBold";

//$default-fonts: "ITC Franklin Gothic Book", "Galano Grotesque SemiBold", "Arial";

$default-fonts: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
