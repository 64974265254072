/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import "~font-awesome/css/font-awesome.css";

// @import "./MyFontsWebfontsKit.css";

@import "./definitions.scss";

.cordova,
.cordova * {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    -webkit-user-select: auto !important;
    -khtml-user-select: auto !important;
    -moz-user-select: auto !important;
    -ms-user-select: auto !important;
    user-select: auto !important;
  }
}

.head-space {
  padding-top: $head-height;
}

.foot-space {
  padding-top: $navigation-links-height-bottom + $navigation-position-height;
}

@media only screen and (min-width: $main-width) {
  .head-space {
    padding-top: $head-height-big + $navigation-links-height-top;
  }

  .foot-space {
    padding-top: $navigation-position-height;
  }
}

.notch {
  height: calc(env(safe-area-inset-top) - 8px); // value from ios is about 12px "too safe"
  min-height: 0;
}

.slit {
  height: calc(env(safe-area-inset-bottom) - 12px); // value from ios is about 12px "too safe"
  min-height: 0;
}

.btn-round {
  background: $dark-green;
  border-radius: 25px;
  border: 0;
  color: #ffffff;
  size: 20px;
  font-weight: bold;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

textarea,
input {
  &::-webkit-input-placeholder {
    color: $grey !important;
  }
  &::-moz-placeholder {
    color: $grey !important;
  }
  &:-ms-input-placeholder {
    color: $grey !important;
  }
  &::placeholder {
    color: $grey !important;
  }
}

:disabled {
  background-color: #e9ecef;
  opacity: 1;
}

// body {
//   background-color: #666;
//   background-image: url("./assets/bkg1.png");
//   overflow-x: hidden;
// }

body {
  // font-family: "Avenir-Book";
  font-family: $default-fonts;
}

h2,
h3 {
  font-weight: 300;
  font-family: $galano;
}

.ios {
  h1,
  h2 {
    letter-spacing: -1px;
  }
}

label {
  display: inline-block;
  white-space: nowrap;
  margin: 0;
  margin-right: 4px;
}

a:hover {
  color: inherit !important;
}

.desktop {
  span.pill:hover {
    background-color: $dark-dark-green !important;
    color: $light-green !important;
  }

  .hover-link:hover {
    color: $dark-green !important;
  }
}

.row-summary {
  margin-top: 8px;
  height: 100%;
  width: 100%;
  line-height: 20px;

  td,
  th {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .column-header {
    padding-right: 10px;

    font-family: $galano;
    font-size: 13px;
    vertical-align: top;
    white-space: nowrap;
  }

  .column-content {
    font-family: $galano;
    font-size: 18px;
    color: $dark-green;

    &.small {
      font-size: 13px;
      font-family: $default-fonts;
    }

    span {
      display: inline-block;
      white-space: nowrap;
      margin-right: 10px;

      &.pill {
        display: inline-block;
        font-family: $default-fonts;
        font-size: 13px;
        background-color: $light-green;
        color: $dark-dark-green;
        border-radius: 3px;
        padding: 2px 8px;
        margin-bottom: 4px;
        margin-right: 4px;
        white-space: nowrap;
        cursor: pointer;

        &.selected {
          background-color: $dark-dark-green;
          color: #ffffff;
        }
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }
}

a {
  display: inline-block;
  cursor: pointer;

  text-decoration: none;
  color: black;
  //   border-bottom: 1px dotted black;

  &:hover {
    text-decoration: none;
    // border-bottom: 1px solid $accent-color-1 !important;
    // border-bottom: 1px solid $dark-green !important;
    // color: $accent-color-1 !important;
    // color: $dark-green !important;
  }
}

.marker-info {
  text-align: center;

  padding: 8px;

  h3 {
    font-size: 18px;
    //font-weight: bold;
  }

  p {
    font-size: $default-font-size;
    padding: 8px;
    margin: 0;
  }
}

.underline {
  text-decoration: underline !important;
}
